<template>
  <div class="group">
    <input v-if='mutType === "tel"' :type="mutType" :id="id" onfocus="this.removeAttribute('readonly');" autocorrect="off" spellcheck="false" :autocomplete="generateString(9)" data-lpignore="true"
           :value="value" @input="changeValue($event.target.value,$event)" @keydown="onKeyDown" @paste="onPaste" @focus="onFocus" @blur="onBlur" required v-mask="'+7(###)###-##-##'">
    <input v-else :type="mutType" :id="id" onfocus="this.removeAttribute('readonly');" autocorrect="off" spellcheck="false" :autocomplete="generateString(9)" data-lpignore="true"
           :value="value" @input="changeValue($event.target.value,$event)"  @paste="onPaste" required>
    <label :for="id" :class="{'title' : value}">{{label}}</label>
    <svg
        v-if="iconClass === 'user'"
        class="group__login"
        width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M13 13.77c0 .078-.008.153-.01.23H2.01c-.003-.077-.011-.153-.011-.23 0-2.311 1.3-4.299 3.173-5.218l.002-.001c.162-.08.33-.151.5-.215l.051-.019a5.12 5.12 0 0 1 .924-.241c.086-.014.171-.027.258-.037l.087-.008a5.396 5.396 0 0 1 .505-.027c.127 0 .252.007.377.016l.049.004.088.008.071.006c.067.007.132.018.197.028h.001c.08.012.157.026.234.041l.152.031a5.417 5.417 0 0 1 1.107.388c1.9.907 3.224 2.912 3.224 5.244ZM6.214 6.69c.052.023.107.042.16.062.048.018.095.039.143.055.312.102.643.157.988.157h.002c.115 0 .227-.006.339-.018.049-.005.097-.015.146-.023.06-.009.123-.016.182-.029.048-.01.092-.025.139-.037.06-.016.12-.03.178-.048.04-.013.077-.03.116-.044.064-.023.128-.045.19-.072.029-.013.055-.03.084-.043.07-.033.14-.066.207-.104l.046-.03c.079-.046.157-.094.23-.146l.006-.004c.058-.042.11-.09.166-.135l.06-.045.013-.011.012-.012a3.537 3.537 0 0 0 1.209-2.676v-.005C10.83 1.559 9.343 0 7.508 0 5.744 0 4.304 1.442 4.196 3.263c-.004.073-.01.145-.01.219-.001 1.442.836 2.68 2.028 3.208Z" fill="#ACACAC" fill-rule="nonzero"/></svg>
    <svg
        v-if="iconClass == 'show'"
        @click="togglePassword"
        class="group__show"
        width="15" height="15" xmlns="http://www.w3.org/2000/svg">
      <path
          v-if="!isShowPassword"
          d="M7.5 2.15c-3.409 0-6.32 2.073-7.5 5 1.18 2.927 4.091 5 7.5 5s6.32-2.073 7.5-5c-1.18-2.927-4.091-5-7.5-5Zm0 8.333c-1.882 0-3.409-1.493-3.409-3.333S5.618 3.817 7.5 3.817s3.409 1.493 3.409 3.333-1.527 3.333-3.41 3.333Zm0-5.333c-1.132 0-2.046.893-2.046 2s.914 2 2.046 2c1.132 0 2.045-.893 2.045-2s-.913-2-2.045-2Z" fill="#ACACAC" fill-rule="evenodd"/>
      <path
          v-else
          d="M5.608 2.167c-1.352.432-2.541 1.19-3.839 2.406C-.04 6.249-.204 6.627.364 7.6c.46.757.46.811-.027 1.352-.379.405-.433.621-.19.865.244.243.46.189.865-.19.514-.459.595-.459.974-.08.351.35.378.54.108 1.027-.487.919.08 1.351.865.621.81-.757 1.649-.378 1.405.568-.297 1.216.892 1.081 1.298-.135.054-.135.351-.162.676-.081.378.108.621.405.675.892.109.946.865.946.974 0 .054-.487.297-.784.675-.892.325-.081.622-.054.676.08.406 1.217 1.595 1.352 1.298.136-.244-.946.594-1.325 1.405-.568.784.73 1.352.298.865-.621-.27-.487-.243-.676.108-1.028.379-.378.46-.378.974.082.405.378.621.432.865.189.243-.244.189-.46-.19-.865-.486-.54-.486-.595-.027-1.352.325-.567.379-.946.19-1.297-.433-.838-2.866-2.947-4.11-3.568-1.27-.65-3.946-.947-5.108-.568Z" fill="#ACACAC" fill-rule="nonzero"/></svg>

  </div>
</template>

<script>
import {mask} from "vue-the-mask";

export default {
  name: "InputAuth",
  props: {
    id : String,
    label: String,
    iconClass: String,
    value: String,
    isTrimEnable: Boolean,
    isRussianSymbolsDisabled: Boolean,
    type: String,
  },
  directives: {
    mask
  },
  data(){
    return {
      isShowPassword: false,
      mutType : 'input'
    }
  },
  watch:{
    type:{
      immediate:true,
      deep: true,
      handler(newVal){
        this.mutType = newVal
      }
    }
  },
  methods:{
    changeValue(value,ev){
      if (this.isTrimEnable){
        value = value.replace(/ /g,'')
      }
      if (this.isRussianSymbolsDisabled){
        value = value.replace(/[^a-zA-Z0-9]+/gm,'')
      }
      ev.target.value = value

      this.$emit('input',value)
    },
    onPaste(e){
      // Stop data actually being pasted into div
      e.stopPropagation();
      e.preventDefault();

      // Get pasted data via clipboard API
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text').trim();
      this.changeValue(pastedData,e)
    },

    generateString(length){
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    togglePassword(){
      if (this.isShowPassword){
        this.isShowPassword = false
        this.mutType = 'password'
      } else {
        this.mutType = 'input'
        this.isShowPassword = true
      }
    },

    onFocus(e){
      if (this.mutType === 'tel'){
        if (this.value.length === 0){
          this.$emit('input',"+7")
        }
      }
    },

    onBlur(e){
      if (this.mutType === 'tel'){
        if (this.value === "+7"){
          this.$emit('input',"")
        }
      }
    },

    onKeyDown(e){
      if (this.mutType === 'tel'){
        if ((e.target.value.length < 3 || e.target.value.length < this.value.length) && isNaN(e.key)){
          e.preventDefault()
          return false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .group{
    width: 290px;
    height: 44px;
    position: relative;
    border-bottom: 1px solid #E3E6EA;

    &__login{
      position: absolute;
      right: 0;
      top: 18px;
    }

    &__show{
      position: absolute;
      right: 0;
      top: 18px;
      cursor: pointer;
    }
  }


  input{
    border: 0;
    outline: 0;
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    font-size: 16px;
    color: #222222;
    letter-spacing: 1px;
    width: 257px;
    margin-top: 16px;

    &:focus ~ label,
    &:valid ~ label{
      font-size: 9px;
      top:-0px;    }
  }


  input[type=password]{
    font-size: 16px;

    @include media(992){
      font-size: 12px;

    }
  }

  label{
    font-family: Roboto, "sans-serif";
    letter-spacing: 1px;
    font-size: 12px;
    color: #ACACAC;
    position: absolute;
    left: 0;
    top:20px;
    transition: all .3s;
    pointer-events: none;
    white-space: nowrap;
  }

  .title{
    font-size: 9px;
    top: 0px;
  }

</style>
